import React from 'react';
import { graphql } from 'gatsby';

import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import Layout from '../../../components/atoms/layout';
import PageTitle from '../../../components/PageTitle';
import { Question } from '../../../template/legal/styles';
import TermOfUseProps from '../../../interface/template';
import { Text2, Text3 } from '../../../components/atoms/body';
import withI18next from '../../../i18n/TemplateI18n';

const TermOfUse: React.SFC<any> = (props: TermOfUseProps) => {
  const { pageContext } = props;
  const { pageName } = pageContext;

  return (
    <>
      <Header pageName={pageName} showBackground>
        <PageTitle subtitle="" title="Term Of Use" />
      </Header>
      <Layout>
        <Question>
          <Text3 as="p">Bem-vindo ao Website da Ironhack </Text3>
          <Text3 as="p">
            Bem-vindo ao website da Ironhack, e obrigada por nos visitar.
            Esperamos que desfrute da experiência.
          </Text3>
          <Text3 as="p">
            Ironhack, Inc. (“<strong>Ironhack</strong>”, “<strong>nó</strong>”,
            “<strong>nos</strong>” ou “<strong>nosso</strong>”) é um fornecedor
            internacional de cursos de Desenvolvimento Web e Design UX/UI com
            campus em diferentes partes do mundo. Estas Condições de utilização
            (“<strong>Condições</strong>”) são de aplicação para o Website da
            Ironhack localizado em{' '}
            <a href="https://www.ironhack.com/pt">https://www.ironhack.com</a>,
            os respetivos centros em todo o mundo e suas filiais e afiliados.
            Estas condições supõem um contrato legal entre si (“
            <strong>si</strong>”, “<strong>seu</strong>”) e nós, e regulam a
            utilização de todos os textos, dados, informação, software,
            gráficos, fotografias e outros elementos (aos quais fazemos
            referência como “<strong>Materiais</strong>”), que tanto nós como as
            nossas filiais e afiliados podem colocar à sua disposição, bem como
            qualquer serviço (“<strong>Serviços</strong>”) que possamos oferecer
            no nosso Website (a todos os anteriores se refere nestas condições
            como “<strong>Website</strong>”)
          </Text3>
          <Text3 as="p">
            LEIA ESTAS CONDIÇÕES ATENTAMENTE ANTES DE NAVEGAR POR ESTE WEBSITE.
            A UTILIZAÇÃO DESTE WEBSITE INDICA QUE LEU E ACEITA ESTAS CONDIÇÕES.
            NÃO PODE UTILIZAR ESTE WEBSITE SE NÃO ACEITAR ESTAS CONDIÇÕES. SE
            NÃO ESTIVER DE ACORDO, NÃO UTILIZE ESTE WEBSITE.
          </Text3>
          <Text3 as="p">
            NOTA: ESTAS CONDIÇÕES CONTÊM UMA DISPOSIÇÃO SOBRE RESOLUÇÃO DE
            DISPUTAS E ARBITRAGEM, INCLUINDO A RENÚNCIA A UAÇÃO JUDICIAL
            COLETIVA QUE AFETA OS SEUS DIREITOS EM VIRTUDE DESTAS CONDIÇÕES E
            RELACIONADOS COM QUALQUER DISPUTA QUE POSSA TER COM A EMPRESA. TAL
            COMO SE ESTABELECE MAIS ADIANTE, PODE RENUNCIAR À ARBITRAGEM
            INDIVIDUAL VINCULANTE E À RENÚNCIA À AÇÃO JUDICIAL COLETIVA.
          </Text3>
          <Text3 as="p">
            A Ironhack reserva-se o direito de alterar, modificar, adicionar ou
            eliminar partes destas Condições, a seu critério exclusivo, e a
            qualquer momento. Reveja estas Condições com regularidade.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">ALTERAÇES</Text2>
          <Text3 as="p">
            Nós, ao nosso critério exclusivo, podemos alterar os Materiais e
            Serviços que oferecemos ou decidir modificar, suspender ou
            interromper este Website a qualquer momento e sem aviso prévio.
            Ocasionalmente, também podemos alterar, atualizar, adicionar ou
            eliminar disposições (de forma coletiva, “modificações”) dessas
            Condições. A clareza beneficia todo o mundo, pelo que prometemos
            informá-lo de qualquer modificação realizada nestas condições
            publicando-o no Website.
          </Text3>
          <Text3 as="p">
            Se não estiver de acordo com tais modificações, o seu único recurso
            será deixar de utilizar este Website. A utilização continuada deste
            Website após a notificação de qualquer das referidas modificações
            indica que reconhece, aceita e está de acordo com a sua vinculação
            através das mesmas. Tenha também presente que estas Condições podem
            ser substituídas por notificações ou condições legais expressamente
            designadas localizadas em páginas concretas deste Website. As
            referidas notificações ou condições expressamente designadas serão
            incorporadas nestas Condições e substituirão as disposições destas
            Condições designadas para isso.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">UTILIZAÇO GERAL</Text2>
          <Text3 as="p">
            Ao utilizar este Website, garante que tem, pelo menos, 18 anos de
            idade. Se ainda não tem 18 anos, tem de obter a autorização de um
            adulto para utilizar este Website e aceitar as suas Condições e o
            referido adulto tem de ser um pai ou um tutor legal que aceite a
            responsabilidade da utilização deste Website .
          </Text3>
          <Text3 as="p">
            Convidamo-lo a utilizar este Website com fins pessoais de consumo (“
            <strong>Fins Permitidos</strong>”). Que o desfrute!
          </Text3>
          <Text3 as="p">
            Através destas Condições, concedemos uma licença limitada, pessoal,
            não exclusiva e não transferível para utilizar e visualizar os
            Materiais. O seu direito para a utilização dos Materiais está
            subordinado ao cumprimento, por suia parte, destas Condições. Não
            tem outros direitos sobre este Website nem sobre nenhum dos
            Materiais e não pode modificar, editar, copiar, reproduzir, criar
            trabalhos derivados de, aplicar engenharia inversa, alterar,
            melhorar ou explorar de forma alguma seja o que for deste Website ou
            dos Materiais. Se efetuar cópias de alguma das partes deste Website
            durante a sua participação em algum dos Fins Permitidos,
            recomendamos-lhe que guarde cópias de todos os nossos direitos de
            autor e outros direitos de propriedade tal e como surgem neste
            Website.
          </Text3>
          <Text3 as="p">
            Apesar disso, caso alguma destas Condições seja infringida, a
            licença anterior vencerá automaticamente e deverá destruir
            imediatamente todos os Materiais que tenha transferido ou impresso
            (bem como todas as respetivas cópias).
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">UTILIZAÇO DESTE WEBSITE E DOS NOSSOS SERVIÇOS</Text2>
          <Text3 as="p">
            Agradecemos a sua visita ao nosso Website e abrimos as portas para
            que dêem uma vista de olhos sem qualquer compromisso e sem se
            registar.
          </Text3>
          <Text3 as="p">
            No entanto, para poder aceder a determinados Serviços, aos nossos
            instrutores ou a materiais específicos de um curso, deverá
            inscrever-se primeiro e depois ser aceite num dos nossos Bootcamps
            de Desenvolvimento Web ou Design UX/UI ou nos nossos programas em
            tempo parcial “part time” (“<strong>Bootcamp Ironhack</strong>”).
          </Text3>
          <Text3 as="p">
            É possível que existam termos e condições adicionais de aplicação a
            determinados serviços ou a partes e funções específicas do Website.
            Ao se inscrever para obter os nossos serviços, aceita tais termos e
            condições.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">REGISTO NA IRONHACK</Text2>
          <Text3 as="p">
            Se pretender registar-se num Bootcamp da Ironhack, deve apresentar
            as seguintes informações, após clicar no botão “
            <a href="https://www.ironhack.com/pt/cursos/inscrever">Apply now</a>
            ” (Registar) deste website:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Nome e apelido;</li>
              <li>Número de telefone;</li>
              <li>Um endereço de correio eletrónico ativo;</li>
              <li>Género;</li>
              <li>Referência; e</li>
              <li>
                Uma breve descrição das razões do seu interesse na Ironhack.
              </li>
            </ul>
            Durante o processo de registo poderá, de forma opcional, fornecer
            informações adicionais, que nos permita proporcionar-lhe uma
            experiência mais personalizada ao utilizar este website, no entanto,
            deixamos esta opção ao seu critério.
          </Text3>
          <Text3 as="p">
            Depois de ter submetido as informações solicitadas, determinaremos
            se a sua inscrição será aceite. Se a mesma for aprovada, receberá
            posteriormente um e-mail, com os detalhes sobre os passos
            necessários para completar o processo de registo. Se não submeter as
            informações acima enumeradas, não será possível processar o seu
            formulário de registo ou fornecer-lhe os nossos serviços da forma
            mais adequada.
          </Text3>
          <Text3 as="p">
            Ao aceder a este website, o utilizador é responsável pelo
            cumprimento destes Termos e Condições.É da responsabilidade do
            utilizador, obter e manter o equipamento e os serviços técnicos
            necessários para aceder e utilizar este website, assim como, também
            é da responsabilidade do utilizador, pagar quaisquer encargos
            associados que se revelem necessários. O utilizador deverá ainda,
            manter a confidencialidade das informações que nos fornecer durante
            o seu registo para o Bootcamp Ironhack. Se considerar que a sua
            informação pessoal ou a sua segurança , foi violada de alguma forma,
            no âmbito da utilização deste website, deverá notificar-nos
            imediatamente. Se necessitar de mais informações, por favor visite a
            nossa{' '}
            <a href="https://www.ironhack.com/pt/legal/aviso-legal">
              Política de Privacidade
            </a>
            .
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">COMUNICAÇES ELETRÓNICAS</Text2>
          <Text3 as="p">
            Ao utilizar este Website, aceita receber comunicações eletrónicas
            nossas, incluindo notificações através de correio eletrónico. Estas
            comunicações eletrónicas podem incluir notificações sobre taxas e
            encargos aplicáveis, informação sobre transações e outra informação
            relacionada com o Website ou o nossos cursos. Estas comunicações
            eletrónicas são parte da sua relação connosco. Aceita que qualquer
            notificação, acordo, divulgação ou outras comunicações que lhe
            enviemos por via eletrónica satisfarão qualquer requisito legal
            sobre comunicações, incluindo que tais comunicações sejam por
            escrito.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">POLÍTICA DE PRIVACIDADE</Text2>
          <Text3 as="p">
            Respeitamos a informação que nos proporciona e queremos
            assegurar-nos de que conhece exatamente como utilizamos essa
            informação. Reveja, por favor, a nossa “
            <strong>Política de Privacidade</strong>” na qual tudo está
            explicado. Ao utilizar este Website, aceita e reconhece que as
            transmissões através da Internet nunca são absolutamente seguras ou
            privadas. Tenha sempre presente que qualquer informação que se envie
            através de, ou para o Website pode ser intercetada por terceiros e
            lida, inclusivamente quando estiver encriptada.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">LIGAÇES PARA WEBSITES DE TERCEIROS</Text2>
          <Text3 as="p">
            Se nos quiser seguir, estar ligado a nós através das redes sociais
            ou aceder a outras páginas web às quais se faz referência na nossa
            página de blog ou Website. Acreditamos que as hiperligações são
            úteis e, por vezes, neste Website oferecemos hiperligações para
            websites de terceiros. Se utilizar estas hiperligações, abandonará
            este Website. Não temos a obrigação de rever os Websites de
            terceiros aos quais pode aceder a partir deste Website, não
            controlamos os Websites de terceiros e não somos responsáveis por
            nenhum Website de terceiro (nem pelos produtos, serviços ou
            conteúdos disponíveis através de nenhum deles). Portanto, não
            confirmamos nem representamos de forma alguma tais Websites de
            terceiros, a informação, conteúdo, software, produtos, Serviços ou
            Materiais que se encontram nos mesmos nem os resultados que se
            possam obter da sua utilização. Se decidir aceder a qualquer dos
            Websites aos quais se fornece ligação a partir deste Website, fá-lo
            sob seu próprio risco e deverá cumprir as políticas de privacidade e
            os termos e condições de tais Websites de terceiros.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">ENVIOS</Text2>
          <Text3 as="p">
            Determinadas áreas deste Website (por exemplo a função de
            comentários do blog), podem permitir-lhe enviar opiniões,
            informação, dados, texto, mensagens ou outros materiais (“
            <strong>Envios de Utilizador</strong>”). Aceita ser o único
            responsável por todos os Envios de Utilizador e que tais Envios de
            utilizador têm o caráter de não confidenciais e não sujeitos a
            direitos de propriedade. Além disso, não garantimos que possa editar
            ou eliminar algum dos Envios de Utilizador que tenha enviado.
          </Text3>
          <Text3 as="p">
            Ao enviar qualquer Envio de Utilizador, garante-nos que:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Possui todos os direitos sobre os seus Envios de Utilizador
                (incluindo entre outros, todos os direitos de reprodução e
                publicação dos seus Envios de Utilizador) ou, de forma
                alternativa, adquiriu todos os direitos necessários sobre os
                Envios de Utilizador que lhe permitem outorgar-nos a nós os
                direitos sobre os seus Envios de Utilizador tal e como se
                descrevem nestas Condições;
              </li>
              <li>
                Pagou e pagará na sua totalidade todas as taxas de licenças,
                taxas alfandegárias e outras obrigações financeiras, sejam de
                que tipo forem, que surjam da utilização e da exploração
                comercial dos seus Envios de Utilizador;
              </li>
              <li>
                Os seus Envios de Utilizador não infringem direitos de autor,
                marcas registadas, patentes, segredos comerciais ou outros
                direitos de propriedade intelectual, direitos de privacidade ou
                qualquer direito legal ou moral de terceiros;
              </li>
              <li>
                Aceita por vontade própria renunciar a todos os “direitos
                morais” que possa ter sobre os seus Envios de Utilizador;
              </li>
              <li>
                Não tem conhecimento de que a informação incluída nos seus
                Envios de Utilizador seja falsa, incorreta ou enganadora;
              </li>
              <li>
                Os seus Envios de Utilizador não infringem nenhuma lei
                (incluindo, mas sem se limitar a, às que regem o controlo de
                exportações, a proteção dos consumidores, a concorrência
                desleal, as políticas antidiscriminatórias ou a falsa
                propaganda);
              </li>
              <li>
                Os seus Envios de Utilizador não são, e não podem ser
                razoavelmente considerados como difamatórios, injuriosos,
                ameaçadores, tendenciosos ou ofensivos a partir de um ponto de
                vista racial, étnico ou religioso, constitutivos de ameaça ou
                coação ilegal a nenhum indivíduo, associação ou corporação,
                vulgares, pornográficos, obscenos ou invasivos da privacidade de
                outras pessoas;
              </li>
              <li>
                Não receberá em momento algum compensação ou consideração de
                terceiras partes pelo envio dos seus Envios de Utilizador;
              </li>
              <li>
                Os seus Envios de Utilizador não incluem materiais de websites
                de terceiros ou moradas postais, endereços de correios
                eletrónico, informação de contacto ou números de telefone (que
                não seja o seu próprio);
              </li>
              <li>
                Os seus Envios de Utilizador não contêm vírus, worms, spyware,
                adware ou outros programas ou ficheiros potencialmente danosos;
              </li>
              <li>
                Os seus Envios de Utilizador não contêm qualquer informação de
                caráter confidencial, protegida com direitos de propriedade ou
                pessoa; e
              </li>
              <li>
                Os seus Envios de Utilizador não contêm nem constituem anúncios
                publicitários não solicitados ou autorizados, material de
                promoção, correio eletrónico não desejado, spam, cartas em
                cadeia, sistemas de pirâmide ou qualquer outra forma de
                convocatória.
              </li>
            </ul>
            Ao fazer um Envio de Utilizador, outorga-nos uma licença
            irrevogável, perpétua, transferível, não exclusiva, paga na sua
            totalidade, internacional e libre de encargos, (sublicenciável
            através de múltiplos níveis) para:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Utilizar, distribuir, reproduzir, modificar, adaptar, publicar,
                traduzir, executar publicamente e mostrar publicamente os seus
                Envios de Utilizador (ou qualquer modificação dos mesmos), em
                parte ou na sua totalidade, em qualquer formato ou meio
                atualmente conhecido ou que se desenvolva no futuro;
              </li>
              <li>
                Utilizar (e permitir que outros utilizem) os seus Envios de
                Utilizador de qualquer forma e para qualquer fim (incluindo, mas
                sem se limitar a fins comerciais) que consideremos apropriados
                sob nosso exclusivo critério (incluindo entre outros, a
                incorporação dos seus Envios de Utilizador ou qualquer
                modificação dos mesmos, em parte ou na sua totalidade, para
                qualquer tecnologia, produto ou serviço);
              </li>
              <li>
                Mostrar anúncios relacionados com os seus Envios de Utilizador e
                utilizar os seus Envios de Utilizador com fins propagandísticos
                e promocionais.
              </li>
            </ul>
            Não toleramos Envios de Utilizador prejudiciais ou ofensivos no
            nosso Website. Podemos, embora não tenhamos a obrigação de, fazer
            uma seleção prévia dos Envios de Utilizador ou controlar qualquer
            espaço deste Website através do qual se possam enviar Envios de
            Utilizador Não temos a obrigação de alojar, publicar ou distribuir
            qualquer Envio de Utilizador em ou através deste Website e podemos
            eliminar a qualquer momento, ou recusar qualquer Envio de Utilizador
            seja por que motivo for. Não somos responsáveis pelas perdas, roubos
            ou danos, sejam do tipo que forem, dos Envios de Utilizador.
            Adicionalmente, aceita que podemos divulgar livremente os seus
            Envios de Utilizador a terceiros sem obrigações de confidencialidade
            por parte do recetor.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">ATIVIDADES NO AUTORIZADAS</Text2>
          <Text3 as="p">
            Para que não haja dúvida alguma, autorizamos a que utilize este
            Website unicamente para os Fins Permitidos. Fica proibida qualquer
            outra utilização deste Website que ultrapasse os Fins Permitidos,
            constituindo uma utilização não autorizada deste Website. Isto assim
            acontece porque no que a si e a nós diz respeito, todos os direitos
            deste Website são propriedade.
          </Text3>
          <Text3 as="p">
            A utilização não autorizada deste Website pode resultar numa
            violação das leis de propriedade intelectual internacionais e de
            vários estados dos Estados Unidos da América. Como queremos que esta
            relação seja o mais saudável possível, proporcionamos-lhe exemplos
            de coisas que deve evitar. Portanto, a menos que disponha de uma
            autorização nossa que estabeleça o contrário, não tem autorização
            para utilizar este Website de nenhuma das formas seguintes (o que
            aqui se mostra são apenas exemplos, e a lista não é uma lista
            completa de tudo o que não tem permissão para fazer):
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Para qualquer fim público ou comercial que inclua a utilização
                deste Website noutro sítio ou através de um ambiente informático
                em rede;
              </li>
              <li>
                De uma forma que modifique, mostre publicamente, execute
                publicamente, reproduza ou distribua nada deste Website;
              </li>
              <li>
                De uma forma que viole qualquer lei, norma, regra, ordem,
                tratado ou legislação de outro tipo local, estatal, nacional,
                estrangeira ou internacional;
              </li>
              <li>Para assediar, importunar ou prejudicar outro indivíduo;</li>
              <li>
                Para forjar qualquer pessoa ou entidade ou falsear de alguma
                outra forma a sua afiliação com uma pessoa ou entidade;
              </li>
              <li>
                Para interferir com, ou alterar este Website ou os servidores ou
                redes ligados ao mesmo;
              </li>
              <li>
                Para utilizar qualquer mineração de dados, robots ou métodos
                similares de recolha ou obtenção de dados relacionados com este
                Website; ou
              </li>
              <li>
                Tentar obter acesso não autorizado a qualquer secção deste
                Website ou qualquer outra conta, sistema informático ou redes
                conectadas a este Website, quer seja através de pirataria,
                extração de palavras-passe, ou qualquer outro meio.
              </li>
            </ul>
            Aceita contratar advogados para a nossa defesa se infringir estas
            Condições e a infração resultar num problema para nós. Também aceita
            assumir os custos de qualquer dano que tenhamos que pagar como
            resultado das suas infrações. Será o único responsável pelas
            infrações que cometa a respeito destas Condições. Reservamo-nos o
            direito de assumir a defesa e o controlo exclusivos de todos os
            assuntos sujeitos de qualquer outra forma a uma indemnização por sua
            parte, e em tal caso, aceita cooperar com a nossa defesa perante tal
            ação.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">DIREITOS DE PROPRIEDADE</Text2>
          <Text3 as="p">
            “Ironhack” é uma marca registada de nossa propriedade. Outras
            marcas, nomes e logótipos neste Website são propriedade dos seus
            respetivos proprietários.
          </Text3>
          <Text3 as="p">
            A menos que se especifique o contrario nestas Condições, todos os
            Materiais, incluindo a sua disposição neste Website são de nossa
            exclusiva propriedade, Copyright © 2018. Ficam reservados todos os
            direitos não garantidos explicitamente neste documento. A menos que
            a legislação aplicável assim o exija, fica proibida toda a
            reprodução, distribuição, modificação, retransmissão ou publicação
            de todo o material com direitos de propriedade intelectual sem o
            consentimento expresso por escrito do proprietário da propriedade
            intelectual ou da licença.
          </Text3>
          <Text3 as="p">
            Antes de utilizar ou reproduzir qualquer marca comercial, nome,
            logótipo ou conteúdo da forma que seja, entre em contacto connosco
            para solicitar autorização. Não obter tal autorização supõe uma
            violação da legislação internacional que protege o autor ou
            proprietário da marca comercial, o nome ou o logótipo ou qualquer
            outro conteúdo e ficará exposto às sanções económicas ou penais
            específicas.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">VIOLAÇO DOS DIREITOS DE PROPRIEDADE INTELECTUAL</Text2>
          <Text3 as="p">
            Respeitamos os direitos de propriedade intelectual de outros e
            esperamos que faça o mesmo. Como consequência, temos a política de
            eliminar qualquer Envio de Utilizador que viole os direitos de
            propriedade intelectual de outros, suspendendo o acesso a este
            Website (ou a qualquer parte do mesmo) a todo o utilizador que o
            utilize em violação dos direitos de propriedade intelectual de
            outros, ou suspendendo o Bootcamp Ironhack de qualquer utilizador
            que utilize este Website em violação dos direitos de propriedade
            intelectual de outros, quando as circunstâncias assim o exijam. Em
            virtude do Título 17 do Código dos Estados Unidos, implantámos
            procedimentos para receber notificações por escrito das ações
            judiciais por infração de direitos de propriedade intelectual e para
            processar tais ações de acordo com essa lei. Se acredita que os seus
            direitos de autor ou outros direitos de propriedade intelectual
            foram violados por um utilizador deste Website, envie uma
            notificação por escrito à Ironhack:
          </Text3>
          <Text3 as="p">C/C: Agente da DMCA da Ironhack</Text3>
          <Text3 as="p">
            Correio electrónico:
            <a href="mailto: legal@ironhack.com">legal@ironhack.com</a>
          </Text3>
          <Text3 as="p">
            Para se assegurar que o tema se trata de forma imediata, a
            notificação por escrito deve:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>incluir a sua assinatura física ou eletrónica;</li>
              <li>
                identificar o trabalho protegido pelos direitos de autor ou
                qualquer outra propriedade intelectual supostamente violados;
              </li>
              <li>
                identificar o material supostamente violado de forma detalhada
                para que possamos localizar esse material;
              </li>
              <li>
                incluir a informação adequada para que possamos contactá-lo
                (incluindo a sua morada, número de telefone e correio
                eletrónico);
              </li>
              <li>
                incluir uma declaração indicando que, de boa fé, considera que a
                utilização do material com direitos de autor ou outros direitos
                de propriedade intelectual não está autorizada pelo
                proprietário, o agente do proprietário ou a lei;
              </li>
              <li>
                incluir uma declaração que indica que a informação na
                notificação por escrito está correta;
              </li>
              <li>
                incluir uma declaração, sob pena de perjúrio, indicando que tem
                autorização para agir em nome do proprietário dos direitos de
                autor ou de qualquer outro direito de propriedade intelectual.
              </li>
            </ul>
            A menos que a notificação faça referência à violação dos direitos de
            autor ou de outros direitos de propriedade intelectual, o Agente da
            DMCA da Ironhack não poderá encarregar-se do assunto citado.
          </Text3>
          <Text3 as="p">Envio de uma contranotificação da DMCA </Text3>
          <Text3 as="p">
            No caso de eliminarmos ou limitarmos o acesso ao material protegido
            por direitos de autor que tenha proporcionado, enviar-lhe-emos uma
            notificação se tal eliminação está sujeita à receção válida de um
            aviso de eliminação da Lei de Direitos de Autor da Era Digital
            (Digital Millennium Copyright Act, DMCA). Em resposta, pode
            proporcionar ao Agente da DMCA da Ironhack uma contranotificação por
            escrito que inclua a seguinte informação:
          </Text3>
          <Text3 as="p">
            <ol>
              <li>a sua assinatura física ou eletrónica;</li>
              <li>
                identificação do material eliminado ou ao qual se limitou o
                acesso, e a localização na qual aparecia o material antes da sua
                eliminação ou que se limitará o seu acesso;
              </li>
              <li>
                uma declaração sob pena de perjúrio, indicando que, de boa fé,
                considera que o material foi eliminado ou o seu acesso limitado,
                como resultado de um erro ou uma falha na identificação do
                material a eliminar ou a limitar o seu acesso;
              </li>
              <li>
                o seu nome, morada e número de telefone, e uma declaração a
                indicar que aceita a jurisdição de um tribunal do distrito
                federal da sua morada, ou se esta for fora dos Estados Unidos,
                de qualquer distrito judicial onde possamos estar localizados, e
                que aceitará a citação da ação judicial da pessoa que facilitou
                a notificação sobre o material alegadamente infrator ou do seu
                agente.
              </li>
            </ol>
            Suspensão de infratores reincidentes
          </Text3>
          <Text3 as="p">
            Reservamo-nos o direito, sob o nosso critério exclusivo, de
            suspender um Bootcamp Ironhack ou o acesso a este Website ou Serviço
            a qualquer utilizador que seja objeto de notificações repetidas da
            DMCA ou outras infrações. As notificações e contranotificações
            deverão satisfazer os requisitos legais em vigor no momento,
            impostos pela DMCA. Para mais informação, consulte a página
            https://www.copyright.gov/. Antes de apresentar uma notificação ou
            uma contranotificação, consulte o seu assessor legal bem como a
            secção 512 do título 17 do Código dos Estados Unidos, dado que, em
            virtude da DMCA existem sanções por reclamações falsas.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">ISENÇO DE GARANTIAS</Text2>
          <Text3 as="p">
            ESTE WEBSITE PROPORCIONA-SE “TAL E QUAL” E “COM TODOS OS ERROS” QUE
            POSSA TER, PELO QUE O UTILIZADOR ASSUME TODOS OS RISCOS RELATIVOS À
            SUA QUALIDADE E RENDIMENTO.
          </Text3>
          <Text3 as="p">
            NÃO PROMETEMOS QUE O WEBSITE OU O SEU CONTEÚDO, SERVIÇOS OU FUNÇÕES
            SE OFEREÇAM DE MANEIRA CONTÍNUA, OPORTUNA, SEGURA OU LIVRE DE ERROS
            OU QUE OS DEFEITOS SEJAM CORRIGIDOS.
          </Text3>
          <Text3 as="p">
            O WEBSITE E O SEU CONTEÚDO PROPORCIONA-SE “TAL E QUAL” E “SEGUNDO
            DISPONIBILIDADE”.
          </Text3>
          <Text3 as="p">
            NÃO PODEMOS GARANTIR QUE OS FICHEIROS OU DADOS QUE TRANSFIRA DESTE
            WEBSITE ESTEJAM LIVRES DE VÍRUS OU CONTAMINAÇÃO OU DE ELEMENTOS
            DESTRUTIVOS.
          </Text3>
          <Text3 as="p">
            NÃO RECONHECEMOS NENHUMA GARANTIA, EXPRESSA, IMPLÍCITA OU LEGAL,
            INCLUINDO AS GARANTIAS DE PRECISÃO EM RELAÇÃO A ESTE WEBSITE,
            INCLUINDO MAS SEM LIMITAR, AS GARANTIAS IMPLÍCITAS DE
            COMERCIABILIDADE, ADEQUAÇÃO A UM FIM OU UTILIZAÇÃO CONCRETAS,
            TITULARIDADE E SEM CONTRAVENÇÃO DOS DIREITOS DE PROPRIEDADE
            INTELECTUAL.
          </Text3>
          <Text3 as="p">
            TAMBÉM RECUSAMOS TODA A RESPONSABILIDADE PELAS AÇÕES, OMISSÕES E
            CONDUTA DE TERCEIROS EM CONEXÃO OU RELACIONADOS COM A SUA UTILIZAÇÃO
            DO WEBSITE OU DOS SERVIÇOS DA IRONHACK.
          </Text3>
          <Text3 as="p">
            ISTO QUER DIZER QUE NÃO PODEMOS PROMETER QUE O WEBSITE NÃO TENHA
            ALGUM PROBLEMA.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">RESULTADOS</Text2>
          <Text3 as="p">
            Não oferecemos garantias relativamente aos resultados que se possam
            obter com a utilização deste Website ou com a exatidão ou
            fiabilidade da informação obtida através do mesmo. Nenhum conselho
            nem informação, seja oral ou por escrito, que tenha obtido deste
            Website ou de nós, das nossas filiais ou outras empresas afiliadas,
            concede alguma garantia. Não assumimos nenhuma compensação
            equitativa.
          </Text3>
          <Text3 as="p">
            Fazemos o possível para contratar os melhores instrutores e pessoal
            para lhe oferecer a melhor experiência possível no Bootcamp
            Ironhack. Podemos apresentar um instrutor específico do Bootcamp
            Ironhack com fins publicitários, mas isso não é uma recomendação de
            tal instrutor ou uma garantia de que o curso em que você se inscreve
            seja dirigido por esse instrutor. Não fazemos declarações sobre a
            qualidade ou natureza de nenhum instrutor do Bootcamp Ironhack.
          </Text3>
          <Text3 as="p">
            É possível que, de vez em quando, façamos publicidade ou promoção
            dos resultados de certos alunos do Bootcamp Ironhack neste Website,
            nas redes sociais ou em centros de promoção. Em nenhum caso a
            Ironhack garante qualquer resultado, trabalho, salário ou fim
            vocacional. O Bootcamp Ironhack não é um programa de inserção
            laboral e certos resultados não poderão ser replicados.
            Esforçamo-nos por ser absolutamente transparentes com os testemunhos
            que apresentamos dos alunos da Ironhack e de resultados prévios,
            incluindo as qualificações médias e as exceções. O nosso objetivo é
            oferecer aos participantes do Bootcamp Ironhack os dados necessários
            para compreender que os resultados do passado não são indicadores
            dos resultados do futuro.
          </Text3>
          <Text3 as="p">
            POR UM ALUNO DA IRONHACK CONSEGUIR UM BOM TRABALHO APÓS COMPLETAR O
            BOOTCAMP IRONHACK NÃO LHE GARANTE A SI O MESMO RESULTADO. OS
            RESULTADOS SÃO ALTAMENTE VARIÁVEIS EM FUNÇÃO DA LOCALIZAÇÃO E
            DEPENDEM DE CONDIÇÕES DE MERCADO QUE FOGEM DO NOSSO CONTROLO. NÃO
            GARANTIMOS NENHUM RESULTADO.
          </Text3>
          <Text3 as="p">
            Acabar um Bootcamp Ironhack não garante que se vá converter num
            perito na matéria ministrada. Os nossos instrutores e pessoal
            trabalham para dirigir a aprendizagem através do Bootcamp Ironhack,
            mas alguns estudantes não acabam satisfatoriamente.
            <strong>
              Alguns estudantes que acabam o curso no Bootcamp Ironhack não
              conseguem um trabalho no seu campo.
            </strong>
            Alguns estudantes não acabam o curso e não adquirem as habilidades e
            conhecimentos da matéria ministrada no Bootcamp Ironhack. Antes de
            se inscrever num Bootcamp Ironhack, é recomendável que fale com um
            Diretor de Programa da Ironhack sobre as diferentes localizações e
            prazos, requisitos de admissão, taxas e despesas associadas assim
            como sobre a sua própria capacidade.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">LIMITAÇO DE RESPONSABILIDADE</Text2>
          <Text3 as="p">
            NÃO NOS RESPONSABILIZAMOS PELOS DANOS RESULTANTES DA SUA
            VISUALIZAÇÃO, CÓPIA OU TRANSFERÊNCIA DOS MATERIAIS DESTE WEBSITE ATÉ
            ONDE A LEGISLAÇÃO APLICÁVEL O PERMITA. EM NENHUM CASO NOS
            RESPONSABILIZAMOS PELOS DANOS INDIRETOS, EXTRAORDINÁRIOS,
            EXEMPLARES, PENALIZANTES, ESPECIAIS, ACIDENTAIS OU CONSEQUENTES
            (INCLUINDO A PERDA DE DADOS, RENDIMENTOS, BENEFÍCIOS, UTILIZAÇÕES OU
            OUTROS BENEFÍCIOS ECONÓMICOS), SEM IMPORTAR O MODO EM QUE SE
            PRODUZIRAM, INCLUINDO O CASO DE SABERMOS DA POSSIBILIDADE DESTES SE
            PRODUZIREM.
          </Text3>
          <Text3 as="p">
            Algumas jurisdições não permitem limitações de responsabilidade, em
            cujo caso não seriam aplicáveis.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">INDEMNIZAÇO</Text2>
          <Text3 as="p">
            Aceita indemnizar e isentar de culpa e encargos a Ironhack, os seus
            diretores, administradores, acionistas, funcionários, agentes,
            filiais ou associados, de todo tipo de reclamações, ações judiciais,
            perdas, responsabilidade ou despesas (incluindo honorários de
            advogados) impostos por terceiros, como resultado ou em relação à
            utilização deste Website.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">LEGISLAÇO LOCAL CONTROLO DE EXPORTAÇO</Text2>
          <Text3 as="p">
            Controlamos e dirigimos este Website a partir da nossa sede nos
            Estados Unidos da América, e é possível que haja partes que não
            sejam adequadas ou não estejam disponíveis noutros lugares.
            Utiliza-se este Website fora dos Estados Unidos da América é da sua
            responsabilidade cumprir as leis aplicáveis.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">
            SOLUÇO DE DISPUTAS E ARBITRAGENS RENÚNCIA A UMA AÇO JUDICIAL
            COLETIVA
          </Text2>
          <Text3 as="p">
            Leia esta disposição com atenção. Afeta os seus direitos legais.
          </Text3>
          <Text3 as="p">
            Esta Disposição de Solução de Disputas e Arbitragens; Renúncia de
            uma Ação Judicial Coletiva (“<strong>Disposição</strong>”) facilita
            a resolução rápida e eficaz de qualquer disputa que possa surgir
            entre você e nós, por exemplo, ações judiciais ou controvérsias, já
            baseadas em contratos, leis, normas, regulamentos, delitos
            (incluindo mas sem limitar, a fraude, falseamento, benefícios
            fraudulentos ou negligência), ou qualquer outra teoria conforme o
            direito ou equidade, incluindo a validade, exigibilidade ou alcance
            desta Disposição (à exceção da aplicabilidade da cláusula de
            Renúncia a uma ação judicial coletiva seguinte). Por isso, “disputa”
            deve interpretar-se no sentido mais amplo possível conforme a lei, e
            inclui qualquer ação judicial contra outras partes, em relação aos
            serviços ou produtos oferecidos ou faturados (como os nossos
            licenciantes, fornecedores, distribuidores ou terceiros vendedores),
            sempre que apresente ações judiciais contra nós no mesmo
            procedimento.
          </Text3>
          <Text3 as="p">
            Esta Disposição estabelece que todas as disputas entre nós deverão
            resolver-se mediante uma arbitragem vinculante, porque a aceitação
            destas Condições constitui uma renúncia ao seu direito a uma ação
            judicial e à oportunidade de ser ouvido por um juiz ou júri.
            Preferimos assim porque acreditamos que a arbitragem é mais eficaz e
            menos polémica que o litígio. Para que não fique nenhuma dúvida, na
            arbitragem não há juízes ou júri e a revisão por um tribunal está
            limitada. O árbitro irá guiar-se por este acordo e tem poder para
            determinar os mesmos danos e soluções que um tribunal (incluindo os
            honorários dos advogados). No entanto, pode renunciar a esta
            disposição, o que suporia ter o direito ou oportunidade de
            apresentar ações judiciais num tribunal, perante um juiz ou um júri,
            ou a participar ou ser representado num caso apresentado num
            tribunal por outros (incluindo mas sem se limitar às ações judiciais
            coletivas). ACEITAMOS QUE, EXCETO O DISPOSTO A SEGUIR, QUALQUER
            DISPUTA, SEGUNDO SE DEFINE ANTERIORMENTE, TANTO ATUAIS COMO BASEADAS
            EM ATOS OU OMISSÕES DO PASSADO OU DO FUTURO, IRÃO RESOLVER-SE,
            EXCLUSIVA E FINALMENTE, MEDIANTE ARBITRAGEM VINCULANTE EM VEZ DOS
            TRIBUNAIS DE ACORDO COM ESTA DISPOSIÇÃO.
          </Text3>
          <Text3 as="p">Resolução de uma Reclamação antes da Arbitragem</Text3>
          <Text3 as="p">
            Para todas as disputas, tanto se são apresentadas nos tribunais ou
            mediante arbitragem, irão dar-nos primeiro a oportunidade de as
            resolver. O primeiro passo é enviar-nos um correio eletrónico para
            <a href="mailto:legal@ironhack.com">legal@ironhack.com</a>
            com a seguinte informação: (1) o seu nome, (2) a sua morada, (3) uma
            descrição por escrito da reclamação e a base da disputa, e (4) uma
            descrição da solução específica que espera. Se não resolvemos a sua
            disputa num prazo de quarenta e cinco (45) dias após a receção da
            sua notificação, pode então levar a disputa para resolução mediante
            arbitragem. Apenas pode apresentar a disputa num tribunal sob as
            circunstâncias que se descrevem a seguir.
          </Text3>
          <Text3 as="p">Exclusões de Arbitragem / Direito à Autoexclusão</Text3>
          <Text3 as="p">
            Sem prejuízo do anterior, você ou nós podemos decidir concluir uma
            disputa nos tribunais e não mediante arbitragem se: (a) a disputa
            cumpre com os requisitos para ser apresentada num tribunal de
            reclamações menores; ou (b) RENÚNCIA AOS PROCEDIMENTOS DE ARBITRAGEM
            NUM PRAZO DE 30 DIAS DESDE A DATA QUE ACEITOU ESTE ACORDO (o “
            <strong>Prazo de Autoexclusão</strong>”). Pode renunciar a esta
            disposição de arbitragem enviando-nos um correio eletrónico para
            <a href="mailto: legal@ironhack.com">legal@ironhack.com</a> com a
            seguinte informação: 1) o seu nome, (2) a sua morada, (3) uma
            declaração clara de que não deseja solucionar qualquer disputa que
            surja connosco mediante arbitragem. Em qualquer caso, não iremos
            tomar a sua decisão pessoalmente. Na verdade, prometemos-lhe que a
            decisão de autoexclusão desta disposição de arbitragem não terá
            nenhum efeito adverso sobre a sua relação connosco. Mas devemos
            aplicar o Prazo de autoexclusão, pelo que tenha presente que
            <strong>
              todos os pedidos de autoexclusão que se receba depois do Prazo de
              Autoexclusão não terão validade e deverá concluir as suas disputas
              mediante arbitragem ou num tribunal de reclamações menores.
            </strong>
          </Text3>
          <Text3 as="p">Procedimentos de Arbitragem</Text3>
          <Text3 as="p">
            Se esta Disposição se aplica e a disputa não se resolve como
            estabelecido anteriormente (Resolução de uma reclamação antes da
            arbitragem) tanto você como nós podemos iniciar os procedimentos de
            arbitragem. A Associação de Arbitragem Americana (American
            Arbitration Association, “<strong>AAA</strong>”), www.adr.org, ou
            JAMS, www.jamsadr.com irão mediar a arbitragem de todas as disputas,
            perante um único árbitro. A arbitragem irá iniciar-se de forma
            individual e nunca como uma arbitragem coletiva ou como uma ação
            judicial ou arbitragem representativa ou consolidada. O árbitro
            tomará as decisões relativamente a todos os temas, incluindo o
            alcance desta disposição de arbitragem.
          </Text3>
          <Text3 as="p">
            No caso das arbitragens perante a AAA, para disputas menores que 75
            000 $, aplicam-se os Supplementary Procedures for Consumer-Related
            Disputes (procedimentos complementares para disputas relacionadas
            com consumidores) da AAA. Quando são de 75 000 $ ou superior, irá
            aplicar-se o Commercial Arbitration Rules (regulamento de arbitragem
            comercial) da AAA. Em ambos os casos irá aplicar-se o Optional Rules
            For Emergency Measures Of Protection (regulamento facultativo para
            medidas de proteção de emergência) da AAA. Os regulamentos da AAA
            estão disponíveis em www.adr.org ou no número de telefone
            1-800-778-7879. No caso de arbitragens perante a JAMS, irá
            aplicar-se o Comprehensive Arbitration Rules &amp; Procedures
            (regulamento geral de arbitragem e procedimentos) e os Recommended
            Arbitration Discovery Protocols For Domestic, Commercial Cases
            (protocolos de arbitragem recomendados para casos domésticos e
            comerciais) de JAMS. O regulamento de JAMS está disponível em
            www.jamsadr.com ou no número de telefone 1-800-352-5267. Esta
            Disposição será a que regule em caso de que exista conflito com o
            regulamento de arbitragem aplicável. Em nenhum caso os regulamentos
            ou procedimentos representativos ou de ações judiciais coletivas
            serão de aplicação à arbitragem.
          </Text3>
          <Text3 as="p">
            Dado que este Website e estas Condições pertencem ao comércio
            interestatal, a lei que regula a arbitragem de todas as disputas é a
            Lei Federal de Arbitragem (Federal Arbitration Act, “
            <strong>FAA</strong>”). No entanto, o árbitro irá aplicar a lei
            substantiva pertinente conforme a FAA e os termos prescritivos
            aplicáveis ou a condição suspensiva, segundo considere.
          </Text3>
          <Text3 as="p">
            <em>Laudo Arbitral</em> – O árbitro pode adjudicar a título
            individual qualquer compensação possível segundo a legislação em
            vigor e não terá a capacidade de outorgar compensação a, em contra
            de ou em benefício de qualquer pessoa que não seja parte do
            procedimento. O árbitro emitirá a sua decisão por escrito mas não
            terá de apresentar uma declaração sobre os motivos da mesma a menos
            que alguma parte assim o solicite ou que seja exigido pela
            legislação aplicável. A decisão será final firme e vinculante para
            as partes, exceto para os direitos de apelação previstos pela FAA ou
            outras leis aplicáveis e poderá apresentar-se em qualquer tribunal
            que tenha jurisdição sobre as partes para efeitos de cumprimento.
          </Text3>
          <Text3 as="p">
            <em>Lugar da Arbitragem</em> – Podemos iniciar a arbitragem tanto na
            Flórida como no distrito judicial federal que corresponda à sua
            morada de faturação.
          </Text3>
          <Text3 as="p">
            <em>Pagamento das Tarifas e Custos de Arbitragem</em> - Sempre que
            solicitado por escrito antes da arbitragem, iremos encarregar-nos do
            pagamento de todos os encargos pelo início da mesma e os encargos
            pelas audiências da AAA ou JAMS, assim como dos honorários, custos e
            despesas do árbitro, se solicitado por escrito ao árbitro
            correspondente, antes ou durante a primeira audiência probatória da
            arbitragem. No entanto, será o responsável de todos os encargos e
            custos adicionais que incorram durante a arbitragem, que incluem
            entre outros, os honorários dos advogados ou dos peritos. Além dos
            encargos e custos recuperáveis de acordo com a legislação aplicável,
            se nos notifica e negoceia de boa fé connosco, segundo se estabelece
            na secção anterior denominada “Resolução de uma reclamação antes da
            arbitragem”, e o árbitro determina que é a parte imperante da
            arbitragem, terá direito a recuperar os honorários e despesas
            razoáveis dos advogados, segundo determine o árbitro.
          </Text3>
          <Text3 as="p">Renúncia à ação judicial coletiva</Text3>
          <Text3 as="p">
            Exceto se estabelecido o contrário nesta disposição de arbitragem, o
            árbitro não poderá consolidar as reclamações de mais de uma pessoa e
            não poderá presidir nenhuma outra forma de ação judicial ou
            procedimento coletivo ou representativo (como uma ação coletiva, uma
            consolidada, uma representativa, ou uma judicial privada) a menos
            que as partes assim o acordemos especificamente por escrito após o
            início da arbitragem. Se decidir apresentar a disputa num tribunal
            após autoexcluir-se desta disposição de arbitragem, tal e como se
            especifica anteriormente, esta Renúncia à ação judicial coletiva não
            será de aplicação. Nem você nem outros utilizadores deste Website
            podem ser representantes de um coletivo, membros de um coletivo ou
            participar de qualquer outra forma num procedimento coletivo,
            consolidado ou representativo sem ter cumprido com os requisitos de
            autoexclusão especificados anteriormente.
          </Text3>
          <Text3 as="p">Renúncia a um júri</Text3>
          <Text3 as="p">
            Entende e está de acordo com que ao aceitar a disposição de
            arbitragem destas Condições, as partes renunciam ao direito a um
            juízo com júri ou a um juízo perante um juiz num tribunal público.
            Se não existir esta disposição de arbitragem, as partes poderiam ter
            tido o direito ou oportunidade de apresentar disputas num tribunal,
            perante um juiz ou um júri, ou a participar ou ser representado num
            caso apresentado por outros num tribunal (incluindo ações judiciais
            coletivas). Exceto indicado de outro modo mais abaixo, estes
            direitos ficam anulados. Outros direitos que deveria ter tido ao
            recorrer a um tribunal (por exemplo, os direitos de apelação e
            certos tipos de descobrimentos) podem ficar limitados ou também
            anulados.
          </Text3>
          <Text3 as="p">Divisibilidade</Text3>
          <Text3 as="p">
            No caso de uma das cláusulas desta disposição de arbitragem (exceto
            a cláusula de Renúncia à ação judicial coletiva) se considerar
            ilegal ou inaplicável, essa cláusula será eliminada desta disposição
            de arbitragem mantendo o resto a sua plena vigência e efeito. Se a
            cláusula de Renúncia à ação judicial coletiva se considerar ilegal
            ou inaplicável, a disposição de arbitragem ao completo será
            inaplicável e a disputa irá resolver-se num tribunal.
          </Text3>
          <Text3 as="p">Continuidade</Text3>
          <Text3 as="p">
            Esta disposição de arbitragem permanecerá em vigor depois que
            complete o seu Bootcamp Ironhack connosco ou com as nossas filiais e
            deixe de utilizar este Website. Não obstante qualquer disposição
            destas Condições em contrário, aceitamos que, se mudarmos de forma
            alguma esta disposição de arbitragem (exceto as mudanças na morada
            de notificações), pode recusar tal mudança e exigir-nos a adesão à
            redação desta disposição no caso de aparecer uma disputa.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">IDIOMA</Text2>
          <Text3 as="p">
            As Partes aqui afetadas exigiram expressamente que este acordo e
            todos os documentos e notificações relacionadas com o mesmo, sejam
            escritos em Inglês.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">GERAL</Text2>
          <Text3 as="p">
            Pensamos que a comunicação direta soluciona a maior parte dos
            problemas (se pensarmos que não cumpre estas Condições, iremos
            informá-lo). Incluindo, e dado que valorizamos esta relação, iremos
            proporcionar-lhe uma recomendação de ações corretivas necessárias.
          </Text3>
          <Text3 as="p">
            No entanto, certas violações destas Condições, segundo o nosso
            critério, podem exigir a suspensão imediata do seu acesso a este
            Website sem que lhe enviemos uma notificação prévia. A Lei Federal
            de Arbitragem, a legislação do estado da Flórida e a legislação
            federal dos E.U. A. aplicável, independentemente da eleição das
            disposições legais ou do conflito de leis, irão regular estas
            Condições. Não se irão aplicar as leis estrangeiras. Exceto disputas
            sujeitas a arbitragem descritas anteriormente, toda a disputa
            relacionada com estas Condições ou este Website irão apresentar-se
            nos tribunais do condado de Miami-Dade, Flórida. No caso de um
            tribunal de jurisdição competente considerar que qualquer das
            disposições destas Condições são nulas, incompatíveis ou
            inaplicáveis, tais disposições serão limitadas ou eliminadas na
            medida estritamente necessária e substituídas com uma disposição
            válida que represente da melhor forma possível as intenções destas
            Condições, de maneira a que estas se mantenham em vigor e aplicáveis
            e não se modifiquem outras condições. A não aplicação destas
            condições não supõe uma renúncia aos nossos direitos. Estas
            Condições constituem o acordo completo entre as partes e, portanto,
            substituem toda a negociação, discussão ou acordo anterior ou atual
            entre qualquer pessoa sobre este Website. Os direitos de propriedade
            intelectual, renúncia de garantias, declarações suas, indemnizações,
            limitações de responsabilidade e disposições gerais irão manter-se
            em vigor após o termo destas Condições.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">NOTIFICAÇO AO CONSUMIDOR DA CALIFÓRNIA</Text2>
          <Text3 as="p">
            De acordo com a secção 1789.3 do Código Civil de Califórnia, os
            utilizadores têm direito à seguinte notificação de direitos do
            consumidor: o fornecedor deste Website e Serviço é a Ironhack, 990
            Biscayne Blvd, Ste. 502, Miami FL 33132. Se tem alguma pergunta ou
            reclamação sobre este Website ou Serviço, pode contactar-nos
            connosco diretamente através do correio eletrónico
            <a href="mailto: legal@ironhack.com">legal@ironhack.com</a>. Também
            pode contactar-nos por escrito para a morada Ironhack, 990 Biscayne
            Blvd, Ste. 502, Miami FL 33132. Os residentes de Califórnia podem
            contactar a Unidade de Assistência ao Consumidor da Divisão de
            Serviços ao Consumidor do Departamento de Assuntos do Consumidor de
            Califórnia através do correio postal na morada 1625 North Market
            Blvd., Sacramento, CA 95834 ou através do número de telefone (916)
            445-1254 ou (800) 952-5210, pessoas com dificuldades auditivas em
            TDD (800) 326-2297 ou TDD (916) 322-1700.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">COMENTÁRIOS</Text2>
          <Text3 as="p">
            Tudo o que nos enviar (por exemplo, comentários, testemunhos,
            citações, perguntas, sugestões, materiais, de forma coletiva “
            <strong>Comentários</strong>”) através de qualquer comunicação da
            natureza que seja (por exemplo, chamadas, comentários no blog,
            correios eletrónicos) terá um caráter não confidencial e de
            propriedade não exclusiva. Exceto disposição legal contrária, pelo
            presente cede todos os direitos, títulos e interesses sobre, e
            podemos utilizar livremente sem atribuição ou contraprestação
            nenhuma para si, qualquer ideia, conhecimento, conceito, técnica ou
            outra propriedade intelectual ou direitos de autor incluídos nos
            Comentários, tanto se são ou não patenteáveis, para o fim, incluindo
            entre outros, o desenvolvimento, a fabricação presente, passada ou
            futura, as licenças, a comercialização e a venda, direta ou indireta
            de produtos e serviços utilizando tais Comentários. Nos casos que a
            cessão anterior está proibida por lei, pelo presente concede-nos uma
            licença exclusiva, transferível, internacional, livre de encargos e
            paga na íntegra (incluindo o direito a sublicenciar) para utilizar e
            explorar todos os Comentários segundo determinemos sob a nossa
            exclusiva discrição. Sem prejuízo do anterior, entende e aceita que
            não temos obrigação alguma de utilizar, publicar, reproduzir ou
            distribuir tais ideias, conhecimentos, conceitos ou técnicas
            incluídas nos Comentários e não tem direito algum de exigir tal
            utilização, publicação, reprodução ou distribuição.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">CONTACTO</Text2>
          <Text3 as="p">
            Se tem alguma pergunta sobre estas Condições ou necessita por outro
            motivo contactar-nos, pode fazer através do endereço
            <a href="mailto: legal@ironhack.com">legal@ironhack.com</a>.
          </Text3>
        </Question>
      </Layout>
      <Footer page={pageName} />
    </>
  );
};

export const query = graphql`
  query {
    locales: allLocale(
      filter: {
        lng: { eq: "pt" }
        ns: { regex: "/(seo)|(breadcrumbs)|(menu)|(banner)/" }
      }
    ) {
      ...TranslationFragment
    }
  }
`;

export default withI18next('pt')(TermOfUse);
